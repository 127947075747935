import React from 'react';
import PropTypes from 'prop-types';

import { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Event from '../../../02_molecules/Event';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBEventsGrid = ({ items = [], behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb bb-events-grid ${styles['bb-events-grid']} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    id={uuid}
  >
    <div className="container wide-grid">
      <div className="row">
        {items.map((item) => (
          <div className="col-12 col-lg-6" key={item.id}>
            <Event {...item} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

BBEventsGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }).isRequired,
      eventName: PropTypes.string.isRequired,
      eventSubtitle: PropTypes.string,
      description: PropTypes.string,
      button: PropTypes.shape({
        label: PropTypes.string,
        nextLink: PropTypes.shape(linkPropTypes),
      }).isRequired,
    }),
  ),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBEventsGrid;
