import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import Button from '../../01_atoms/Button';

const Event = ({ image, eventName, eventSubtitle = '', description = '', button }) => (
  <div className={`event ${styles.event}`}>
    <div className="view-box">
      <div className="image-box">
        <div className="image" style={{ backgroundImage: `url(${image.url})` }} title={image.alt} />
      </div>
      <div className="content-box">
        <div className="content">
          <h3 className="title">{eventName}</h3>

          {eventSubtitle && <div className="subtitle">{eventSubtitle}</div>}

          {description && <div className="description">{description}</div>}
        </div>
        <Link {...button.nextLink}>
          <Button
            tag="a"
            href={button.nextLink.url}
            type="secondary"
            className="event-button"
            withArrow
            size="large"
          >
            {button.label}
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

Event.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  eventName: PropTypes.string.isRequired,
  eventSubtitle: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }).isRequired,
};

export default Event;
